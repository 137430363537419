import "@babel/polyfill";
import "./nav";
import { jarallax } from "jarallax";
import { createObject, createFooterObject, setThemeColor } from "./utils";

const baseApi = "https://wp.goboat.dk/wp-json/wp/v2/";
const host = window.location.hostname;
var path = window.location.pathname;
var pageSlug = "";
if (path.indexOf(".html") !== -1) path = path.substr(0, path.length - 5);
switch (path) {
  case "/":
  case "/index":
  case "/frontpage":
    pageSlug = "frontpage";
    break;
  default:
    pageSlug = path.substr(1, path.length);
    break;
}

let shopUrl = "";
switch (host) {
  case "goboat.dk":
  case "www.goboat.dk":
  case "denmark.goboat.dk":
  case "www.denmark.goboat.dk":
    shopUrl = `${baseApi}shop_settings?slug=shop-settings-denmark`;
    break;
  case "goboat.com.au":
  case "www.goboat.com.au":
  case "australia.goboat.dk":
  case "www.australia.goboat.dk":
    shopUrl = `${baseApi}shop_settings?slug=shop-settings-australia`;
    break;
  case "goboat.co.uk":
  case "www.goboat.co.uk":
  case "uk.goboat.dk":
  case "www.uk.goobat.dk":
    shopUrl = `${baseApi}shop_settings?slug=shop-settings-uk`;
    break;
  default:
    shopUrl = `${baseApi}shop_settings?slug=shop-settings-australia`;
    break;
}

const getLocations = async userId => {
  let locations = await fetch(`${baseApi}locations?author=${userId}`);
  locations = await locations.json();
  locations = locations.map(location => (location = location.acf));
  return locations;
};

const newElement = (type, { classList = [], text = "" } = {}) => {
  let element = document.createElement(type);
  element.classList.add(...classList);
  element.innerHTML = text;
  return element;
};

const renderLocations = locations => {
  locations.map(data => {
    if (data.location_enable) {
      let location = document.createElement("div");
      location.classList.add("location");

      if (data.location_custom_banner) {
        let customBanner = newElement("div", { classList: ["coming-soon"] });
        let customBannerText = newElement("span", {
          text: data.location_custom_banner_text
        });
        customBanner.appendChild(customBannerText);
        location.appendChild(customBanner);
      }

      if (data.location_coming_soon) {
        let comingSoon = newElement("div", { classList: ["coming-soon"] });
        let comingSoonText = newElement("span", { text: "Coming Soon" });
        comingSoon.appendChild(comingSoonText);
        location.appendChild(comingSoon);
      }

      let locationImage = document.createElement("img");
      locationImage.src = data.location_background_image.url;
      locationImage.alt = data.location_background_image.alt;
      locationImage.classList.add("background-image", "small", "round-corner");
      location.appendChild(locationImage);

      let locationLogo = document.createElement("img");
      locationLogo.src = data.location_logo.url;
      locationLogo.alt = data.location_logo.alt;
      locationLogo.classList.add("logo");
      location.appendChild(locationLogo);

      let locationContentContainer = document.createElement("div");
      locationContentContainer.classList.add("content-container");

      let locationTitle = document.createElement("h3");
      locationTitle.innerHTML = data.location_title;
      locationTitle.style.color = `var(--${data.location_title_color})`;
      locationContentContainer.appendChild(locationTitle);

      let hr = document.createElement("hr");
      locationContentContainer.appendChild(hr);

      let locationContent = document.createElement("p");
      locationContent.innerHTML = data.location_content;
      locationContentContainer.appendChild(locationContent);

      location.appendChild(locationContentContainer);

      let locationLink = document.createElement("a");
      locationLink.href = data.location_link;

      locationLink.appendChild(location);

      document.querySelector("#row").appendChild(locationLink);
    }
  });
};

const renderPage = page => {
  Object.entries(page).forEach(([section, value]) => {
    Object.entries(value).forEach(([element, val]) => {
      if (value.enable === true) {
        let domElement = document.querySelector(`#${section}_${element}`);
        if (domElement) domElement.innerHTML = val;
        let contentBox = document.querySelector(`#${section}_content_box`);
        let form = document.querySelector(`#${section}_form`);
        switch (element) {
          case "background_image":
            let domImg = document.querySelector(`#${section}_image`);
            if (domImg) domImg.src = val.url;
            break;
          case "background_blur":
            let domElement = document.querySelector(`#${section}_image`);
            if (domElement) domElement.classList.add(`blur-${val}`);
            break;
          case "background_parallax":
            let parallaxElement = document.querySelector(`#${section}_image`);
            if (parallaxElement && val === true) {
              parallaxElement.classList.add(`jarallax-img`);
              parallaxElement.parentNode.classList.add(`jarallax`);
              jarallax(document.querySelectorAll(".jarallax"), {
                speed: 0.5
              });
            }
            break;
          case "title_color":
          case "content_color":
            let type = element.substring(0, element.indexOf("_"));
            let domTitle = document.querySelector(`#${section}_${type}`);
            if (domTitle) domTitle.style.color = setThemeColor(val);
            break;
          case "content_box_position":
            if (contentBox) contentBox.style.alignSelf = val;
            break;
          case "min_height":
            if (element !== "none") {
              document.querySelector(`#${section}`).style.minHeight = val;
            }
            break;
          case "video_link":
            if (val) {
              let iframe = document.createElement("iframe");
              iframe.src = val;
              iframe.setAttribute("frameborder", "0");
              iframe.setAttribute(
                "allow",
                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              );
              iframe.setAttribute("allowfullscreen", "");
              let iframeWrapper = document.createElement("div");
              iframeWrapper.classList.add("iframe_wrapper");
              iframeWrapper.append(iframe);
              iframeWrapper.append(iframe);
              if (document.querySelector(`#${section}`)) {
                document.querySelector(`#${section}`).append(iframeWrapper);
              }
            }
            break;
        }
      } else {
        if (document.querySelector(`#${section}`)) {
          document.querySelector(`#${section}`).style.display = "none";
        }
      }
    });
  });
};

const renderFooter = footer => {
  if (footer.enable === true) {
    const footerDOM = document.querySelector("#footer");
    const img = document.createElement("img");
    img.src =
      "https://wp.goboat.dk/wp-content/uploads/2018/04/goboat-logo-white.svg";
    let footerElement = document.createElement("div");
    footerElement.classList.add("footer_element");
    footerElement.appendChild(img);
    footerDOM.appendChild(footerElement);
    var buildObject = footer;
    delete buildObject.enable;
    Object.values(buildObject).map(section => {
      if (section.enable === true) {
        let footerElement = document.createElement("div");
        footerElement.classList.add("footer_element");

        let h3 = document.createElement("h3");
        h3.innerHTML = section.title;
        footerElement.appendChild(h3);

        let p = document.createElement("p");
        p.innerHTML = section.content;
        footerElement.appendChild(p);

        footerDOM.appendChild(footerElement);
      }
    });
  }
};

const setAnalytics = function(gtmId) {
  const w = window;
  const d = document;
  const s = "script";
  const l = "dataLayer";
  const i = gtmId;

  if (i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    return f.parentNode.insertBefore(j, f);
  }
};

const setLogo = (logo, name) => {
  const logoElements = document.querySelectorAll(".logoImg");
  const logoTitle = document.querySelector("#header_logo_title");
  logoElements.forEach(element => (element.src = logo));
  if (logoTitle) {
    logoTitle.innerHTML = name;
  }
};

const getPage = async region => {
  let page = await fetch(`${baseApi}pages?slug=region-${pageSlug}-${region}`);
  page = await page.json();
  page = page[0].acf;
  page = createObject(page);
  return page;
};

const getShop = async url => {
  let shop = await fetch(url);
  shop = await shop.json();
  shop = createObject(shop[0].acf);
  if (shop.site.analytics.gtmId) setAnalytics(shop.site.analytics.gtmId);
  if (shop.site.analytics.gtmId2) setAnalytics(shop.site.analytics.gtmId2);
  if (shop.site.analytics.gtmId3) setAnalytics(shop.site.analytics.gtmId3);
  setLogo(shop.site.logo.logo.url, shop.site.name);
  document.title = "GoBoat " + shop.site.name;
  let page = await getPage(shop.site.slug);
  var footer = createFooterObject(page[pageSlug].footer);
  renderPage(page[pageSlug]);
  const locations = await getLocations(shop.site.userId);
  const sortedLocs = locations.sort((a, b) =>
    a.location_order > b.location_order ? 1 : -1
  );
  if (pageSlug === "frontpage") {
    renderLocations(sortedLocs);
  }
  renderFooter(footer);
};

getShop(shopUrl);
