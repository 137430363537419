// Input:
//    {
//      category_section_element: val,
//      category_section_element: val...
//    }
export const createObject = data => {
  let object = {};
  Object.entries(data).forEach(entry => {
    let parts = entry[0].split("_");

    // <------------------------------------------------------------------------------------------------------------------------------>
    // DELETE AFTER REFACTURING

    switch (parts[0]) {
      // if menu depth is only section_element (not category_section_element), add case below:
      // case "example":
      case "menu":
      case "title":
      case "description":
        parts[1] = entry[0]
          .substring(
            entry[0].indexOf("_", entry[0].indexOf("_")),
            entry[0].length
          )
          .slice(1, entry[0].length);
        parts.splice(2);
        break;
      // if menu depth is only category_element(_optional_sub_values) (not category_section_element), add case below:
      // case "example":
      case "site":
        switch (parts[1]) {
          case "logo":
            parts[2] = entry[0]
              .substring(entry[0].indexOf("_", 0), entry[0].length)
              .slice(1, entry[0].length);
            break;
        }
        break;
      default:
        parts[2] = entry[0]
          .substring(
            entry[0].indexOf("_", entry[0].indexOf("_") + 1),
            entry[0].length
          )
          .slice(1, entry[0].length);
        parts.splice(3);
        break;
    }

    // <------------------------------------------------------------------------------------------------------------------------------>

    if (parts[0]) {
      if (!(parts[0] in object)) {
        object[parts[0]] = {};
      }
      if (parts.length > 2) {
        if (!(parts[1] in object[parts[0]])) {
          object[parts[0]][parts[1]] = {};
        }
        if (!(parts[2] in object[parts[0]][parts[1]])) {
          object[parts[0]][parts[1]][parts[2]] = entry[1];
        }
      } else {
        object[parts[0]][parts[1]] = entry[1];
      }
    }
  });
  return object;
};
// Output:
//  {
//    category: {
//        section: {
//           element: val
//        }
//     }
//  }

export const createFooterObject = footer => {
  const returnObject = {};
  Object.entries(footer).map(entry => {
    var key = entry[0].split("_");
    if (key.length === 1) {
      if (!returnObject[key[0]]) {
        returnObject[key[0]] = entry[1];
      }
    }
    if (key.length === 2) {
      if (!returnObject[key[0]]) {
        returnObject[key[0]] = {};
      }
      if (!returnObject[key[0]][key[1]]) {
        returnObject[key[0]][key[1]] = entry[1];
      }
    }
  });
  return returnObject;
};

export const removeFirstSlash = string => {
  if (string.length > 1) {
    string = string.substr(1, string.length);
  }
  return string;
};

export const setThemeColor = color => {
  switch (color) {
    case "goboat-blue":
      return (color = "#3D527A");
    case "goboat-dark-blue":
      return (color = "#2E3D5C");
    default:
      return color;
  }
};
